import { useEffect, useState} from 'react'
import { SiReact, SiJavascript, SiRedux, SiPython, SiRubyonrails, SiVuedotjs, SiGit, SiAdobe } from 'react-icons/si'
import { PiStarBold, PiStarFill, PiStarHalfFill, PiDownloadSimpleBold } from 'react-icons/pi'
import { BsCircleHalf, BsCircleFill, BsCircle } from 'react-icons/bs'
import aboutPicture from '../../assets/images/about.png'
import mobileAboutPic from '../../assets/images/mobileAbout.jpeg'
import robResume from '../../assets/robhcho-resume.pdf'
import { TypingAnimation } from '../TypingAnimation'
import { CSSTransition } from 'react-transition-group'

const techArray = [
  {label: 'Javascript', icon: <SiJavascript className='icon'/>, color: '#f0db4f', rating: 5},
  {label: 'React', icon: <SiReact className='icon'/>, color: '#61dbfb', rating: 5},
  {label: 'Redux', icon: <SiRedux className='icon'/>, color: '#764abc', rating: 4},
  {label: 'Vue', icon: <SiVuedotjs className='icon'/>, color: '#41b883', rating: 4.5},
  {label: 'Ruby Rails', icon: <SiRubyonrails className='icon'/>, color: '#cc0000', rating: 3.5},
  {label: 'Python', icon: <SiPython className='icon'/>, color: '#4b8bbe', rating: 3.5},
  {label: 'Git', icon: <SiGit className='icon'/>, color: '#f1502f', rating: 4},
  {label: 'Adobe', icon: <SiAdobe className='icon'/>, color: '#ff0000', rating: 4.5},
]

const renderRating = (rating) => (
  <div className='inline-flex'>
    { rating % 1 === 0 ?      
      <>
        {[...Array(Math.floor(rating))].map((item, i) => (
          <BsCircleFill key={i} style={{fontSize: '10px', margin: '0 2px'}}/>
        ))}
        {[...Array(5 - Math.floor(rating))].map((item, i) => (
          <BsCircle key={i} style={{fontSize: '10px', margin: '0 2px'}}/>
        ))}
      </>
    :
      <>
        {[...Array(Math.floor(rating))].map((item, i) => (
          <BsCircleFill key={i} style={{fontSize: '10px', margin: '0 2px'}}/>
        ))}
        <BsCircleHalf  style={{fontSize: '10px', margin: '0 2px'}}/>
        {[...Array(5 - Math.floor((rating + 1)))].map((item, i) => (
          <BsCircle key={i} style={{fontSize: '10px', margin: '0 2px'}}/>
        ))}
      </>
    }    
  </div>
)

export const About = ({isActive}) => {
  const [showHeader, setShowHeader] = useState(false)
  const [showDescription, setShowDescription] = useState(false)
  const [showTech, setShowTech] = useState(false)
  const [showList, setShowList] = useState(false)

  useEffect(() => {
    if (isActive) {      
      setTimeout(() => {setShowHeader(true)}, 300)
      setTimeout(() => {setShowDescription(true)}, 600)
      setTimeout(() => {setShowTech(true)}, 900)
      setTimeout(() => {setShowList(true)}, 1200)
    }
  }, [isActive])

  useEffect(() => {
    if(showList) {      
      const listItems = document.querySelectorAll('.tech-group')      
      listItems.forEach((item, idx) => {
        setTimeout(() => {
          item.classList.add('visible')
        }, idx * 200)        
      })      
    }
  }, [showList])    
  
  return <section id='about' className={`relative flex-shrink-0 h-screen py-10 section justify-center md:justify-normal ${isActive ? 'active' : ''}`}>
    <div className='w-full pr-5 mt-20 md:mt-0 md:w-1/2 md:pr-0'>
      {/* <div className='about-overlay'></div> */}
      {console.log(isActive)}
      {/* <div className='flex'> */}
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div className='mobile-pic-container mt-5'>
          <img src={mobileAboutPic} className='mobile-pic'/>
        </div>
      </div>
        <h3 className={`about-header text-4xl md:text-5xl mt-3 ${showHeader ? 'visible' : ''}`}>A little about myself</h3>
      {/* </div> */}
      <p 
        className={`description fade-in py-5 ${showDescription ? 'visible' : ''}`}        
      >
        I'm a <span className='highlight'>Software Engineer</span> with a background in graphic design based in <span className='highlight'>New York City</span>.
        I have a keen eye for detail, a creative mindset, and a passion for technology. I specialize in crafting high-functioning and innovative software solutions.
        My goal is not just to build applications, but to translate complex ideas into elegant and user-friendly experiences.

        Driven by a relentless pursuit of excellence, I thrive on challenges and enjoy pushing the boundaries of what's possible. Whether it's designing intuitive user interfaces
        or architecting robust backend systems, I am committed to delivering solutions that exceed expectations.
      </p>      

      <div>
        <p className={`tech-intro fade-in ${showTech ? 'visible' : ''}`}>
          Here are a few of the <span className='highlight'>technologies</span> I have been working with:          
        </p>
              
        <div className={`tech-list grid grid-cols-4 gap-x-10 gap-y-4 mt-10 ${showList ? 'visible' : ''}`}>
          {techArray.map(({label, icon, color, rating}, idx) => (
            <div 
            key={idx} 
            className="tech-group flex items-center transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300"
            >
              <div className='tooltip'>
                {renderRating(rating)}
              </div>
              <i className="mr-1 md:mr-2" style={{color: `${color}`}}>{icon}</i>
              <p className='tech-label'>{label}</p>
            </div>
          ))}
        </div>      
        <div className='space'/>
      </div>        
    </div>
    <button className={`resume-btn absolute md:right-10 mt-5 ${showHeader ? 'visible' : ''}`}>
      <a href={robResume} download='robhcho-resume.pdf' className={`resume-btn ${showHeader ? 'visible' : ''}`}>
        <PiDownloadSimpleBold className='mr-2'/>
        <span>Download Resume</span>
      </a>
    </button>
    {/* <div className={`about-me-pic ${showHeader ? 'visible' : ''}`}> */}
    {/* <div className={`about-me-pic`}>
      <img src={aboutPicture}/>
    </div>     */}
  </section>
}