import { useState } from 'react'
import { HiChatAlt } from 'react-icons/hi'
import { IoClose } from 'react-icons/io5'
import { FiSend } from 'react-icons/fi'

export const Contact = ({show}) => {
  const [openForm, setOpenForm] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    msg: '',
  })

  const handleChange = (e) => {
    const {name, value} = e.target

    setFormData({...formData, [name]: value})
  }

  const toggleForm = () => {
    setTimeout(() => {setOpenForm(!openForm)}, 200)
  }

  const handleSubmit = async (e) => {
    console.log('hello, submit btn clicked')
    e.preventDefault()    

    try {
      await fetch('https://us-central1-robhcho-update.cloudfunctions.net/app/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })

      console.log('Email send successfully')

      setFormData({name: '', email: '', msg: ''})
    } catch (err) {
      console.log('error', err)
    } 
  }
  
  return <div id='contact' className={`${show ? 'visible' : ''}`}>  
      {openForm &&
        <div className='relative'>
          <button className='close-btn fixed right-5' onClick={toggleForm}><IoClose/></button>          
          <form onSubmit={handleSubmit} className={`form ${openForm ?  'visible' : ''}`}>
            <div className='form-control'>
              <label className='text-sm' htmlFor='name'>NAME: </label>
              <input 
                type='text'
                id='name'
                name='name'
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className='form-control'>
              <label className='text-sm' htmlFor='email'>EMAIL: </label>
              <input 
                type='email'
                id='email'
                name='email'
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className='form-control'>
              <label className='text-sm' htmlFor='msg'>MESSAGE: </label>
              <textarea
                id='msg'
                name='msg'
                value={formData.msg}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button type='submit' className='w-full submit-btn'>SEND<FiSend className='ml-1'/></button>
          </form>
        </div>      
      }      
      <HiChatAlt 
        className={`${openForm ? 'chat-active' : 'chat-bubble'} text-slate-700 p-3`}
        onClick={toggleForm}
      />
  </div>
}