import { useRef, useEffect, useState } from 'react'
import navLogo from '../assets/images/roblogo-xl.png'
import { NavLink } from 'react-router-dom'
import { RiLinkedinBoxFill, RiGithubFill, RiBehanceFill } from 'react-icons/ri'
import { CSSTransition } from 'react-transition-group'

export const Nav = ({handleNavChange}) => {
  const [showNav, setShowNav] = useState(false)  
  const navbarRef = useRef(null)
  const [isloading, setIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 300)
    setTimeout(() => {setShowNav(true)}, 500)
    
    setTimeout(() => {
      const listItem = document.querySelectorAll('.nav-item')
      listItem.forEach((item, idx) => {
        setTimeout(() => {
          item.classList.add('visible')
        }, idx * 150)
      })
    }, 350)
    
  }, [])

  if(isloading) return <></>
  
  return (        
      <nav 
        ref={navbarRef}
        className={`navbar fixed left-0 top-0 w-full md:h-full p-4 flex md:flex-col justify-between md:w-1/3 ${showNav ?  'visible' : ''}`}
      >
        <div className='flex md:block'>
          <NavLink to='/' exact onClick={() => handleNavChange('/')}>
            <img src={navLogo} className='nav-logo mr-10 md:my-4 md:mt-5'/>
          </NavLink>
          <ul className='space-x-5 my-5 text-zinc-400 flex md:block md:space-y-4 md:my-0 md:space-x-0 md:mt-10 brackets'>
            {['home', 'about', 'projects'].map((section, idx) => (
              <CSSTransition
                key={section}
                in={showNav}
                timeout={500}
                classNames='nav-item'
                style={{transitionDelay: `${idx * 500}ms`}}
                unmountOnExit
              >
                <li onClick={() => handleNavChange(section !== 'home' ? section : '/')}>
                  <NavLink
                    to={section !== 'home' ? `/${section}` : '/'}
                    className={({isActive}) => 
                      `nav-item ${isActive ? 'active' : 'inactive hover:translate-y-1 hover:scale-110 duration:300 visible'}`
                    }
                  >
                    {({isActive}) => (
                      <>
                      {section.toUpperCase()}
                      {isActive ? '.' : ''}                      
                      </>                    
                    )}
                  </NavLink>                  
                </li>
              </CSSTransition>
            ))}                        
          </ul>          
        </div>
        <div className='flex space-x-2 md:space-x-4'>
          <i><a href='https://www.linkedin.com/in/robhcho/'>
            <RiLinkedinBoxFill className={`linkedin text-xl ml-4 my-4 md:my-0 md:ml-0 md:text-3xl text-zinc-400 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 hover:text-blue-500 ${showNav ? 'visible' : ''}`}/>
          </a></i>
          <i><a href='https://github.com/robhcho'>
            <RiGithubFill className={`github text-xl my-4 md:my-0 md:text-3xl text-zinc-400 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 hover:text-slate-950 ${showNav ? 'visible' : ''}`}/>
          </a></i>
          <i><a href='https://www.behance.net/robhcho'>
            <RiBehanceFill className={`behance text-xl my-4 md:my-0 md:text-3xl text-zinc-400 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 hover:text-blue-800 ${showNav ? 'visible' : ''}`}/>
          </a></i>
        </div>
      </nav>    
  )
}