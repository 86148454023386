import { useState, useEffect, useRef } from 'react'
import { projects } from '../projectsData'
import { IoArrowBack } from 'react-icons/io5'

export const Projects = ({isActive}) => {  
  const [projectDisplay, setProjectDisplay] = useState(null)
  const [projectSelected, setProjectSelected] = useState(false)
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(null)
  const [hover, setHover] = useState(false)
  const projectRef = useRef(null)

  const handleProjectSelected = (project, index) => {    
    setTimeout(() => {setProjectSelected(true)}, 150)
    setProjectDisplay(project)
    setSelectedProjectIndex(index)
  }  

  useEffect(() => {    
    if(isActive) {
      setTimeout(() => {
        const listItems = document.querySelectorAll('.project')
        listItems.forEach((item, idx) => {
          setTimeout(() => {
            item.classList.add('visible')
          }, idx * 200)
        })
      }, 500)
    }
    
    if(projectSelected) {      
      setTimeout(() => {
        const techItems = document.querySelectorAll('.tech')
        techItems.forEach((item, idx) => {
          setTimeout(() => {
            item.classList.add('visible')
          }, idx * 150)
        })
      }, 700)
    }
    
    if(!isActive) {
      handleGoBack()
    }
  }, [isActive, projectSelected])

  const handleGoBack = () => {
    setProjectSelected(false)
    setProjectDisplay(null)
    setSelectedProjectIndex(null)
    setHover(false)
  }

  const handleMouseOver = () => {
    setHover(true)
  }

  const handleMouseLeave = () => {
    setHover(false)
  }
  
  return <section id='projects' className={`relative flex-shrink-0 py-10 section ${isActive ? 'active' : ''}`}>    
    <div className='relative project-card-group'>

      <div className='project-display-container'>
        {projectDisplay && (
          <div className={`project-display ${projectSelected ? 'visible' : ''}`}>            
            <button className={`back-btn duration-300 ${projectSelected ? 'visible' : ''}`} onClick={handleGoBack} onMouseEnter={handleMouseOver} onMouseLeave={handleMouseLeave}>              
              <IoArrowBack className={`back-arrow ${hover ? 'hovered' : ''}`}/>
              <p className={`back-text ${hover ? 'hovered' : ''}`}>GO BACK</p>
            </button>
            <img src={projectDisplay.logo} className={`md:mt-5 w-1/2 project-logo ${projectSelected ? 'visible' : ''}`}/>            
            <hr className='my-3 w-5/6'/>
            <p className={`project-category text-zinc-300 text-lg ${projectSelected ? 'visible' : ''}`}>{projectDisplay.category.toUpperCase()}</p>
            <p className={`project-description w-5/6 ${projectSelected ? 'visible' : ''}`}>{projectDisplay.description}</p>
            <br/>            
            <hr className='my-3 w-5/6'/>
            <div className='flex'>
              {projectDisplay.tech.map((item, i) => (
                <div key={i} className='flex tech'>
                  <span className='text-zinc-400'>·</span>
                  <p key={i} className='mx-1 text-xs text-zinc-400'>{item}</p>
                </div>
              ))}
            </div>
            <div className={`project-images ${projectSelected ? 'visible' : ''}`}>
              {Object.keys(projectDisplay.gallery).filter(img => img !== 'feature' && img !== 'mobile_feature').map((img, i) => (
                <img key={i} src={projectDisplay.gallery[img]}/>
              ))}
            </div>
          </div>
        )}
      </div>
            
      <div className='project-list flex bottom-28'>        
        {projects.map((project, idx) => (
          <p 
            key={idx} 
            className={`project text-xl text-zinc-400 hover:-translate-y-1 hover:scale-110 hover:text-zinc-600 duration-300 ${
              projectSelected && selectedProjectIndex !== idx ? 'hidden' : ''
            } ${selectedProjectIndex === idx ? 'selected' : ''}`} 
            onClick={() => handleProjectSelected(project, idx)}
          >
            {project.project_name.toUpperCase()}
          </p>
        ))}
      </div>
    </div>
  </section>
}