import { useState, useEffect } from 'react'
import { Nav } from './components/Nav'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Home, About, Projects } from './components/sections'
import { NotFound } from './components/NotFound'
import { Contact } from './components/Contact'
import { Sample } from './pages/Sample'
import { DisableHorizontalScroll } from './components/DisableHorizontalScroll'
import './assets/index.css'

const App = () => {
  const [activeSection, setActiveSection] = useState('/')
  const [showContact, setShowContact] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShowContact(true)
    }, 1500)    
  }, [])
  
  return <Router>
    <div id='app' className='flex'>    
      <Nav handleNavChange={setActiveSection}/>
      <main className='app-main'>
        <Routes>
          <Route path='/' element={<Home isActive={activeSection === '/'} handleNavChange={setActiveSection}/>}/>
          <Route path='/about' element={<About isActive={activeSection === 'about'}/>}/>
          <Route path='/projects' element={<Projects isActive={activeSection === 'projects'}/>}/>
          <Route path='*' element={<NotFound/>} />
        </Routes>
      </main>      
      <Contact show={showContact} className={`contact-component ${showContact ? 'visible' : ''}`}/>
    </div>
  </Router>
}

export default App