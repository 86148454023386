import { useRef, useState, useEffect } from 'react'
import aboutMePhoto from '../../assets/images/about.png'
import introImg from '../../assets/images/intro-desaturate.png'
import { useNavigate } from 'react-router-dom'
import { IoArrowForwardOutline } from 'react-icons/io5'

export const Home = ({isActive, handleNavChange}) => {
  const projectsRef = useRef(null)
  const [showCursor, setShowCursor] = useState(true)
  const [showChars, setShowChars] = useState(false)
  const [showGreeting, setShowGreeting] = useState(false)
  const [showName, setShowName] = useState(false)
  const [charIndex, setCharIndex] = useState(0)
  const [showTitle, setShowTitle] = useState(false)
  const [showRole, setShowRole] = useState(false)
  const [hover, setHover] = useState(false)
  const greetText = 'Hello! I\'m'
  const navigate = useNavigate()

  useEffect(() => {
    if(isActive) {
      const delayTimer = setTimeout(() => {
        setShowGreeting(true)
        setTimeout(() => {setShowName(true)}, 50)
        setTimeout(() => {setShowRole(true)}, 750)      
      }, 500)
    }
  }, [isActive])

  const handleHovered = () => {
    setTimeout(() => {
      setHover(true)
    }, 175)
  }

  const handleClick = () => {
    navigate('/projects')
    handleNavChange('projects')
  }

  const handleHide = () => {
    setHover(false)
  }

  return <section id='home' className={`flex-shrink-0 h-screen section ${isActive ? 'active' : ''}`}>
    <div className='relative flex w-screen home-container'>
      <div className='relative img-section'>
        <img src={introImg} className='intro-img'/>        
        <div className='intro-overlay'>
          <div className={`greeting md:text-sm ${showGreeting ? 'visible' : ''}`}>IT'S NICE TO MEET YOU!</div>
          <div className='absolute top-2/3 pl-5 intro'>
          </div>
          <div className={`arrow-container ${showName ? 'visible' : ''}`} onMouseEnter={handleHovered} onMouseLeave={handleHide} onClick={handleClick}>
            <p 
              className={`home-btn ${hover ? 'hovered' : 'hidden'}`}              
            >
              GO TO PROJECTS
            </p>
            <IoArrowForwardOutline className={`home-arrow`} />
          </div>
        </div>
      </div>      
      <div className='text-content'>
        <h1 className={`full-name text-3xl md:text-4xl ${showName ? 'visible' : ''}`}>ROB CHO</h1>
      </div>
    </div>
    <div><img className={`home-photo ${isActive ? 'visible' : ''}`} src={aboutMePhoto} /></div>
  </section>
}