import smartdashFeature from '../assets/images/smartdash-feature.jpg'
import timetableFeature from '../assets/images/timetable-feature.jpg'
import actualizeFeature from '../assets/images/actualize-feature.jpg'
import smartdashFeature2 from '../assets/images/smartdash-opt2.jpg'
import timetableFeature2 from '../assets/images/timetable-opt2.jpg'
import actualizeFeature2 from '../assets/images/actualize-opt2.jpg'
import smartdashLogo from '../assets/SmartDash.png'
import actualizelogo from '../assets/actualizelogo.png'
import timetablelogo from '../assets/timetablelogo.png'
import smartdash1 from '../assets/images/smartdash-carousel1.jpg'
import smartdash2 from '../assets/images/smartdash-carousel2.jpg'
import actualize1 from '../assets/images/actualize-carousel1.jpg'
import actualize2 from '../assets/images/actualize-carousel3.jpg'
import timetable1 from '../assets/images/timetable-carousel1.jpg'
import timetable2 from '../assets/images/timetable-carousel2.jpg'

export const projects = [
  {
    logo: smartdashLogo,
    project_name: 'SmartDash',
    category: 'Data Analytics',
    gallery: {
      feature: smartdashFeature2,
      mobile_feature: smartdashFeature,
      image_1: smartdash1,
      image_2: smartdash2,        
    },
    description: 'SmartDash™ revolutionizes the landscape of consumer data aggregation, providing clients with a comprehensive platform to effortlessly track and evaluate the efficacy of their marketing investments. By consolidating diverse datasets into a singular interface, SmartDash™ empowers clients to gain unprecedented insights into the impact of each marketing dollar expended. Offering unparalleled flexibility, clients can tailor their dashboards to spotlight the metrics and key performance indicators (KPIs) most pertinent to their objectives. Whether analyzing customer retention rates, customer lifetime values, demographic shifts across locations, or incremental return on investment (ROI), SmartDash™ equips clients with the tools to navigate the complexities of modern marketing with ease and precision.',
    tech: ['NodeJS', 'ReactJS', 'Material-UI', 'Coldfusion', 'MySQL'],
    demo: '',
  },
  {
    logo: timetablelogo,
    project_name: 'TimeTable',
    category: 'Event/Time Management',
    gallery: {
      feature: timetableFeature2,
      mobile_feature: timetableFeature,
      image_1: timetable1,
      image_2: timetable2,
      image_3: '',    
    },
    description: 'TimeT4ble empowers users to seamlessly synchronize their schedules within a group setting, TimeT4ble facilitates the effortless sharing of individual calendars. Employing sophisticated algorithms, it meticulously parses through each member\'s timetable, meticulously discerning the optimal dates based on their respective availabilities.',
    tech: ['VueJS', 'Bootstrap', 'Ruby on Rails', 'PostgresSQL'],
    demo: '',
  },
  {
    logo: actualizelogo,
    project_name: 'Actualize CRM',
    category: 'Customer Relations',
    gallery: {
      feature: actualizeFeature2,
      mobile_feature: actualizeFeature,
      image_1: actualize1,
      image_2: actualize2,
      image_3: '',    
    },
    description: 'ActualizeCRM serves as an indispensable tool in Actualize\'s endeavor to streamline and enhance communication with potential applicants. Through its robust features, ActualizeCRM enables the organization to efficiently manage and organize interactions with prospective candidates. Leveraging automated SMS messaging capabilities, Actualize can seamlessly engage with leads in real-time, tailoring messages to their specific active statuses with precision and effectiveness.',
    tech: ['VueJS', 'eRuby', 'Twilio API'],
    demo: '',
  },
]